<template>
  <div>
    <v-container fluid>
      <v-card>
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Pesquisar na Tabela"></v-text-field>
          <v-spacer></v-spacer>
          <input id="inputArquivo" type="file" hidden :multiple="false" @change="carregarConteudo" :disabled="carregando" />
          <v-btn color="primary" @click="carregarItens()" icon><v-icon>cached</v-icon></v-btn>
          <v-btn color="primary" @click="escolherArquivo()" icon><v-icon>add</v-icon></v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :headers="headers" :items="itens" :search="busca" :loading="carregando">
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.codigo_cidade }}</td>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.codigo_estado }}</td>
              <td>{{ props.item.estado }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import constantes from '@/util/constantes';

export default {
  data() {
    return {
      headers: [
        { text: 'Cód. Cidade', value: 'codigo_cidade', sortable: false },
        { text: 'Cidade', value: 'cidade' },
        { text: 'Cód. Estado', value: 'codigo_estado' },
        { text: 'Estado', value: 'estado' },
      ],
      constantes: constantes,
      busca: '',
    };
  },

  computed: {
    ...mapState('cidadeEstado', {
      itens: 'cidades',
      carregando: 'carregando',
    }),
  },

  methods: {
    escolherArquivo() {
      document.getElementById('inputArquivo').click();
    },

    carregarConteudo(evento) {
      const arquivo = evento.target.files[0];
      if (arquivo) {
        var reader = new FileReader();
        var vm = this;
        reader.onload = (e) => {
          vm.enviarConteudo(e.target.result);
        };
        reader.readAsText(arquivo, 'UTF-8');
      }
    },

    enviarConteudo(dados) {
      this.$store.dispatch('cidadeEstado/tratarConteudo', dados);
    },

    carregarItens() {
      this.$store.dispatch('cidadeEstado/carregarItens');
    },
  },
};
</script>
